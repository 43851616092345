import React from "react"
import { Link } from "gatsby"
import "./dropdownMenu.css"

const activeStyle = {
  display: 'block',
	height: '19px',
	border:'solid 1px #6d7488',
	'text-decoration':'none',
	color:'#6d7488',
	'padding-left': '0',
	'padding-right':'0',
	'padding-top':'2px',
  'margin-left': '0',
  'margin-bottom': '5px',
  'margin-top': '5px',
  'text-align': 'center',
}

class DropdownMenu extends React.Component {
  state = {
    droped: false
  }
  down = event => {
    this.setState(current => ({droped: true}));
  }
  up = event => {
    this.setState(current => ({droped: false}));
  } 
  render() {
    return (
        <div class="dropdown-menu" onMouseEnter={this.down} onMouseLeave={this.up} role = "button" tabIndex={0}>
            <div class="dropdown-menu-title">
                <div>{this.props.title} </div>
            </div>
            <div class={"dropdown-menu-content " + (this.state.droped ? 'show' : 'hidden')}>
                <Link class="tdt" to={this.props.firstlink} title={this.props.title} activeStyle={activeStyle}>
                  <div>Narcastet</div>
                </Link>
                <Link class="tdt" to={this.props.secondlink} title={this.props.title} activeStyle={activeStyle}>
                  <div>Rosny-sur-Seine</div>
                </Link>
            </div>
        </div>
    )
  }
}
export default DropdownMenu