import React from "react"
import "./footer.css"
import contactTitre from "../assets/images/contact/titre_contact_.png"
import qrcode from "../assets/images/contact/QR_Code_EC3D.jpg"

class Footer extends React.Component {
    render() {
        return (
            <div class={"footer " + (this.props.translated === "true" ? 'translated' : 'not-translated')}>
                <div class="footer_in">
                    <div class="contact">
                        <div class="content-title h1"><img src={contactTitre} width="166" height="22" alt="Rubrique Contact" /></div>
                        <div class="contact-content">
                            <div class="h3">
                                5 Z.A. du Pont d’ Assat - 64510 NARCASTET<br />
                                Tél. : +33 (0)5 59 82 15 21   <br />   
                                Fax. : +33 (0)5 59 82 15 20
                            </div>
                            <div class="h3">
                                <strong>Jean-François LECA </strong><br />
                                Mobile : +33 (0)6 85 12 62 41<br />
                                Email :  jf.leca@ec3d.fr
                            </div>
                            <div class="h3">
                                <strong>Coordonnées GPS</strong><br />
                                lat. : 43.24318739 <br />
                                long. : -0.30944762 
                            </div>
                        </div>
                    </div>
                    <div class="qrcode">
                        <img src={qrcode} width="117" height="100" alt="QR-Code contact EC3D" />
                    </div>
                </div>
                <div class={"footer_bott " + (this.props.background === "true" ? 'right' : 'left')}>
                    <div class="legal">
                        <a href="mentions_legales_et_plan_du_site.html" target="_blank" rel="noopener noreferrer">
                            Mentions légales & Plan du Site
                        </a>
                    </div>
                    <div class="developer">
                        Site réalisé par Dellie - 
                        <a href="http://www.dellie.fr/" target="_blank" rel="noopener noreferrer">
                            www.dellie.fr
                        </a>
                        et Laurent - 
                        <a href="https://www.linkedin.com/in/laurent-blanc-pattin-665901170" target="_blank" rel="noopener noreferrer">
                            voir le profil
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer
