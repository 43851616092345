import { Link } from "gatsby"
import React from "react"
import "./header.css"
import logo from "../assets/images/logo_ec3d.jpg"
import puce from "../assets/images/accueil/puce_menu.gif"
import DropDownMenu from "../components/dropdownMenu"

const activeStyle = {
	height: '19px',
	border:'solid 1px #6d7488',
	'text-decoration':'none',
	color:'#6d7488',
	'padding-left': '4px',
	'padding-right':'4px',
	'padding-top':'2px',
	'margin-left': '5px'
}

const Header = () => (
    <div id="header">
        <div id="logo">
        <Link to="/"><img src={logo} border="0" width="168" height="70" alt="Retour à l'accueil EC3D" /></Link>
        </div>
        <div id="menu">
            <div class="menu-wrapper">
                <Link to="/" activeStyle={activeStyle} class="tdt">
                  <div>Accueil</div>
                </Link>
                <div class="separator"></div>
                <Link class="tdt" to="/savoirFaire" title="Savoir-faire et Qualité chez EC3D" activeStyle={activeStyle}>
                  <div>Savoir-Faire et Qualité</div>
                </Link>
                <div class="separator"></div>
                <DropDownMenu title="Équipement et locaux" firstlink="/equipement-narcastet" secondlink="/equipement-rosny-sur-seine"></DropDownMenu>
                <div class="separator"></div>
                <DropDownMenu title="Contact et accès" firstlink="/contact-narcastet" secondlink="/contact-rosny-sur-seine"></DropDownMenu>
                <img id="menu-puce" src={puce} width="25" height="21" alt="puce menu EC3D" />
            </div>
        </div>
    </div>
)

export default Header
